<template>
    <li>
        <item-card>
            <template v-slot:title>
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-300">
                    {{ document.documentable_info.type_name }}
                </h3>
                <template v-if="document.documentable_info.type === 'GoodsWriteOff' && document.documentable.is_accepted">
                    <Badge text="Akceptēts" className="bg-green-100 text-green-800 mr-2 mb-2 md:mb-0"/>
                </template>
            </template>

            <template v-slot:buttons>
                <span class="relative z-0 inline-flex rounded-md ml-3 items-center">
                    <template v-if="status">
                        <Badge :text="status.name" :className="`${statusColor(status.id)} mr-2 mb-2 md:mb-0`"/>
                    </template>

                    <template v-if="!displayForm('EditWriteOffDocumentDetails')">
                        <template v-if="document.documentable_info.type === 'GoodsWriteOff'">
                            <Button @click="showForm('EditWriteOffDocumentDetails')" icon="pencil" />
                        </template>

                        <template v-if="!loading && document.documentable.status_id === 3">
                            <Button icon="briefcase-download" @click="downloadXML" />
                        </template>

                        <template v-if="!loading && document.documentable_info.type === 'GoodsWriteOff'">
                            <Button @click="downloadWriteOffXML" icon="briefcase-download-outline" tooltip="Lejuplādēt XML" />
                        </template>
                        <template v-if="!loading && document.documentable_info.type === 'GoodsWriteOff'">
                            <Button @click="downloadWriteOffPdf" icon="download-folder" tooltip="Lejuplādēt PDF" />
                        </template>

                        <template v-if="!document.documentable.is_accepted">
                            <Button icon="delete" @click="deleteIem" />
                        </template>
                    </template>
                </span>
            </template>

            <template v-slot:content>
                <template v-if="!displayForm('EditWriteOffDocumentDetails')">
                    <item-text title="Dokumenta datums" :text="document.document_date"/>
                    <template v-if="document.user">
                        <item-text title="Izveidoja" :text="document.user"/>
                    </template>

                    <template v-if="document.receiver_user">
                        <item-text title="Pieņēma" :text="document.receiver_user.received_by.name"/>
                    </template>

                    <template v-if="document.receiver_user">
                        <item-text title="Pieņemšamas laiks" :text="document.receiver_user.received_at"/>
                    </template>

                    <template v-if="document.sender">
                        <item-text title="No" :text="document.sender.name"/>
                    </template>

                    <template v-if="document.receiver">
                        <item-text title="Uz" :text="document.receiver.name"/>
                    </template>

                    <template v-if="document.documentable.reason">
                        <item-text title="Pamatojums" :text="document.documentable.reason"/>
                    </template>
                </template>

                <template v-if="displayForm('EditWriteOffDocumentDetails')">
                    <EditWriteOffDetails :document="document" />
                </template>
            </template>
        </item-card>
    </li>
</template>

<script>
import {mapGetters} from "vuex";
import Colors from "@/services/Helpers/colors";
import ItemCard from "@/components/Components/ItemCard"
import ItemText from "@/components/Components/ItemText"
import Badge from "@/components/Components/Badge"
import EditWriteOffDetails from "@/components/Stock/TehnicalDocuments/WriteOff/EditWriteOffDetails"

export default {
    name: "TehnicalDocumentDetails",
    components: {
        ItemCard,
        ItemText,
        Badge,
        EditWriteOffDetails,
    },
    data() {
        return {
            statuses: [
                {id: 1, name: 'Jauns'},
                {id: 2, name: 'Piegādāts'},
                {id: 3, name: 'Pabeigts'},
            ],
        }
    },
    props: ["document", "status"],
    computed: {
        ...mapGetters({
            loading: 'loading',
            formsForDisplay: 'formsForDisplay',
        })
    },
    methods: {
        statusColor(status) {
            let key = status ? status : 0
            return Colors.statusColor(key)
        },
        downloadXML() {
            this.$store.dispatch('getGoodsRemovalXml', this.document.documentable)
        },
        deleteIem() {
            if(confirm('Vai esi drošs?')){
                this.$Progress.start()
                this.$store.dispatch('deleteDocument', this.document.id)
            }
        },
        downloadWriteOffXML() {
            this.$store.dispatch('getWriteOffXML', this.document)
        },
        downloadWriteOffPdf() {
            this.$store.dispatch('getWriteOffPdf', this.document)
        },

        showForm(formName){
            this.$store.dispatch('addFormForDisplay', formName)
        },
        displayForm(formName){
            return this.formsForDisplay.includes(formName)
        },
    },
}
</script>

<style>
</style>