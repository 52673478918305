<template>
    <template v-if="form">
        <form class="space-y-6 w-full py-4">
            <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div class="col-span-1">
                    <Input name="date" type="date" placeholder="Dokumenta datums" v-model="form.date" :errors="errors.date" />
                </div>

                <div class="col-span-1">
                    <Input name="reason" placeholder="Pamatojums" v-model="form.reason" :errors="errors.date" />
                </div>
            </div>

            <div class="flex justify-end">
                <button 
                    @click="hideAddForm"
                    type="button"
                    class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2"
                >
                    Atcelt
                </button>

                <template v-if="!loading">
                    <button
                        type="button"
                        class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                        @click.prevent="submit"
                    >
                        Labot
                    </button>
                </template>
                <template v-else>
                    <Loading />
                </template>
            </div>
        </form>
    </template>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment"
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";

export default {
    name: "EditWriteOffDetails",
    components: {
        Input,
        Loading,
    },
    props: {
        document: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            form: null
        }
    },
    created() {
        this.form = {
            date: moment(this.document.document_date, 'DD.mm.yyyy').format('yyyy-mm-DD'),
            reason: this.document.documentable.reason,
        }
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                date: {
                    rules: ['required']
                },
                reason: {
                    rules: ['required']
                },
            }
        }
    },
    methods: {
        hideAddForm(){
            this.$store.dispatch('removeAllFormsForDisplay')
        },
        submit() {
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateWriteOff", {
                    id: this.document.id,
                    document_id: this.document.documentable.id,
                    data: this.form
                });
            } else {
                this.$Progress.fail();
            }
        },
    }
}
</script>

<style>

</style>